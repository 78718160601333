import { useModal } from 'vue-final-modal'

import CreateSubscriber from '~/components/modal-windows/CreateSubscriber.vue'
import Action from '~/enums/action'
import type SubscriptionForm from '~/enums/subscription-form'
import type { BannerData } from '~/types/banners'
import type { PopupData } from '~/types/popups'

export function useInteraction () {
  async function onClick (data: BannerData | PopupData): Promise<void> {
    const router = useRouter()

    const { action, actionParameters, path, url } = data.attributes

    if (action) {
      if (
        action === Action.CreateSubscriber
        && typeof actionParameters?.modalWindowTitle === 'string'
        && typeof actionParameters?.subscriptionFormId === 'string'
      ) {
        await useModal({
          component: CreateSubscriber,
          attrs: {
            modalWindowTitle: actionParameters.modalWindowTitle,
            subscriptionForm: actionParameters.subscriptionFormId as SubscriptionForm
          }
        }).open()
      }
    } else if (path) {
      await router.push(path)
    } else if (url) {
      await navigateTo(url, { open: { target: '_blank' } })
    }
  }

  return { onClick }
}
